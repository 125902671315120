import { Mapping } from "/app/src/models";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { mappingService } from "/app/src/services";
import { handlePromiseError } from "/app/src/helpers/api";
import { buildParams } from "/app/src/helpers";

/**
 * Hook to return the data required for a grouping confirmation mapping
 */
export default function GroupingConfirmationHook(groupingMapping: Mapping) {
  const queryClient = useQueryClient();
  const { data: childMappings } = useQuery({
    queryKey: ["groupingConfirmationMappings", groupingMapping.id],
    queryFn: () => {
      return mappingService.getAll(
        buildParams({
          parentMappingId: groupingMapping.id,
        }),
      );
    },
    enabled: Boolean(groupingMapping.id),
    initialData: { mappings: [] },
    select: (data: { mappings: Mapping[] }) => {
      return data.mappings;
    },
  });

  const { mutateAsync: addMapping } = useMutation({
    mutationFn: (mapping: Omit<Mapping, "parentMapping" | "children">) => {
      return mappingService
        .createSingle({ ...mapping, parentMappingId: groupingMapping.id })
        .then(handlePromiseError);
    },
    onSuccess: (response) => {
      queryClient.setQueryData(
        ["groupingConfirmationMappings", groupingMapping.id],
        (oldData: { mappings: Mapping[] }) => {
          return {
            mappings: [...oldData.mappings, response.mapping],
          };
        },
      );
    },
  });

  const { mutateAsync: removeMapping } = useMutation({
    mutationFn: (mapping: Mapping) => {
      return mappingService.deleteSingle(mapping.id).then(() => {
        const mappingId = mapping.id;
        return { mappingId };
      });
    },
    onSuccess: (response) => {
      queryClient.setQueryData(
        ["groupingConfirmationMappings", groupingMapping.id],
        (oldData: { mappings: Mapping[] }) => {
          return {
            mappings: oldData.mappings.filter(
              (mapping) => mapping.id !== response.mappingId,
            ),
          };
        },
      );
    },
  });

  const { mutateAsync: updateMapping } = useMutation({
    mutationFn: (mapping: Omit<Mapping, "parentMapping" | "children">) => {
      return mappingService
        .updateSingle(mapping.id, mapping)
        .then(handlePromiseError);
    },
    onSuccess: (response) => {
      queryClient.setQueryData(
        ["groupingConfirmationMappings", groupingMapping.id],
        (oldData: { mappings: Mapping[] }) => {
          return {
            mappings: oldData.mappings.map((mapping) => {
              if (mapping.id === response.mapping.id) {
                return response.mapping;
              }
              return mapping;
            }),
          };
        },
      );
    },
  });

  return {
    childMappings,
    addMapping,
    removeMapping,
    updateMapping,
  };
}

import { settingService } from "/app/src/services";
import { Integration, Setting } from "/app/src/models";
import { buildParams } from "/app/src/helpers/params";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { handlePromiseError } from "/app/src/helpers/api";

/**
 * DataHook for the settings of the integration
 */
export default function DataHook(integration: Integration) {
  const queryClient = useQueryClient();

  const consolidateLinesQuery = useQuery({
    queryKey: ["consolidateLines", integration.id],
    queryFn: () =>
      settingService.getAll(
        buildParams({
          integrationId: integration.id,
          type: "consolidateLines",
        }),
      ),
  });

  const filterSettingsQuery = useQuery({
    queryKey: ["filterSettings", integration.id],
    queryFn: () =>
      settingService.getAll(
        buildParams({
          integrationId: integration.id,
          type: "filter",
        }),
      ),
    initialData: { settings: [] },
    select: (data: { settings: Setting[] }) => {
      return data.settings;
    },
  });

  const { mutateAsync: deleteFilterSettingMutator } = useMutation({
    mutationFn: (id: number) =>
      settingService
        .deleteSingle(id)
        .then(handlePromiseError)
        .then(() => id),
    onSuccess: (id) => {
      queryClient.setQueryData(
        ["filterSettings", integration.id],
        (oldData: { settings: Setting[] }) => {
          return {
            settings: oldData.settings.filter((setting) => setting.id !== id),
          };
        },
      );
    },
  });

  const { mutateAsync: updateFilterSettingMutator } = useMutation({
    mutationFn: (values: { id: number; value: string; name: string }) =>
      settingService
        .updateSingle(values.id, {
          value: values.value,
          name: values.name,
        })
        .then(handlePromiseError),
    onSuccess: (data) => {
      queryClient.setQueryData(
        ["filterSettings", integration.id],
        (oldData: { settings: Setting[] }) => {
          const newSettings = oldData.settings.map((setting) => {
            if (setting.id === data.setting.id) {
              return data.setting;
            }
            return setting;
          });
          return { settings: newSettings };
        },
      );
    },
  });

  const { mutateAsync: createFilterSettingMutator } = useMutation({
    mutationFn: (values: { value: string; name: string }) =>
      settingService
        .createSingle({
          name: values.name,
          value: values.value,
          type: "filter",
          integrationId: integration.id,
        })
        .then(handlePromiseError),
    onSuccess: (data) => {
      queryClient.setQueryData(
        ["filterSettings", integration.id],
        (oldData: { settings: Setting[] }) => {
          return { settings: [...oldData.settings, data.setting] };
        },
      );
    },
  });

  const { mutateAsync: consolidateLinesMutator } = useMutation({
    mutationFn: (values: { value: boolean | undefined }) => {
      if (consolidateLinesQuery.data?.settings.length < 1) {
        return settingService
          .createSingle({
            name: "consolidateLines",
            value: values.value ? 1 : 0,
            type: "consolidateLines",
            integrationId: integration.id,
          })
          .then(handlePromiseError);
      } else {
        return settingService
          .updateSingle(consolidateLinesQuery.data?.settings[0].id, {
            name: "consolidateLines",
            value: values.value ? 1 : 0,
            type: "consolidateLines",
            integrationId: integration.id,
          })
          .then(handlePromiseError);
      }
    },
    onSuccess: (data) => {
      queryClient.setQueryData(["consolidateLines", integration.id], {
        settings: [data?.setting],
      });
    },
  });

  return {
    consolidateLinesQuery,
    consolidateLinesMutator,
    filterSettingsQuery,
    deleteFilterSettingMutator,
    updateFilterSettingMutator,
    createFilterSettingMutator,
  };
}

import { Form, Select } from "formik-antd";
import { Integration, ReportColumn as ReportColumnT } from "/app/src/models";
import { useQuery } from "@tanstack/react-query";
import { reportColumnService } from "/app/src/services";
import { buildParams } from "/app/src/helpers";
import { useTranslation } from "react-i18next";

/**
 *
 * @returns ReportColumn component
 */
export default function ReportColumn({
  integration,
}: {
  integration: Integration;
}) {
  const { t } = useTranslation();
  const { data: columns } = useQuery({
    queryKey: ["columns", integration.reportId],
    queryFn: () => {
      return reportColumnService.getAll(
        buildParams({ reportId: integration.reportId }),
      );
    },
    initialData: { report_columns: [] },
    select: (data: { report_columns: ReportColumnT[] }) => {
      return data.report_columns;
    },
  });
  return (
    <Form.Item className="w-full !mb-0 " name="columnId" hasFeedback={false}>
      <Select
        size="middle"
        name="columnId"
        placeholder={t("translation:select_column")}
      >
        {columns.map((column) => (
          <Select.Option value={column.id} key={column.id}>
            {column.name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
}

import { Integration, Mapping } from "/app/src/models";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { connectionService, mappingService } from "/app/src/services";
import { handlePromiseError } from "/app/src/helpers/api";
import { buildParams } from "/app/src/helpers";

/**
 * Hook to handle the confirmation mappings for a single Data Pull Integration
 */
export default function ConfirmationHook({
  integration,
}: {
  integration: Integration;
}) {
  const queryClient = useQueryClient();
  const { data: mappings } = useQuery({
    queryKey: ["mappingsForConfirmation", integration.id],
    queryFn: () => {
      return mappingService.getAll(
        buildParams({
          integrationId: integration.id,
          orderby: "key",
          type: "[or]column;id",
        }),
      );
    },
    enabled: Boolean(integration.id),
    initialData: { mappings: [] },
    select: (data: { mappings: Mapping[] }) => {
      return data.mappings;
    },
  });
  const { data: confirmationMappings } = useQuery({
    queryKey: ["confirmationMappings", integration.id],
    queryFn: () => {
      return mappingService.getAll(
        buildParams({
          integrationId: integration.id,
          mappingType: "Confirmation",
          parentMappingId: "none",
        }),
      );
    },
    enabled: Boolean(integration.id),
    initialData: { mappings: [] },
    select: (data: { mappings: Mapping[] }) => {
      return data.mappings;
    },
  });
  const { data: connections } = useQuery({
    queryKey: ["connections", "[or]REST;GraphQL;MSSQL;IBM2"],
    queryFn: () => {
      return connectionService.getAll(
        buildParams({ type: "[or]REST;GraphQL;MSSQL;IBM2" }),
      );
    },
    initialData: { connections: [] },
    select: (data = { connections: [] }) => {
      return data.connections;
    },
  });
  const { mutateAsync: addMapping } = useMutation({
    mutationFn: (mapping: Omit<Mapping, "parentMapping" | "children">) => {
      return mappingService
        .createSingle({ ...mapping, integrationId: integration.id })
        .then(handlePromiseError);
    },
    onSuccess: (response) => {
      queryClient.setQueryData(
        ["confirmationMappings", integration.id],
        (oldData: { mappings: Mapping[] }) => {
          return {
            mappings: [...oldData.mappings, response.mapping],
          };
        },
      );
    },
  });

  const { mutateAsync: removeMapping } = useMutation({
    mutationFn: (mapping: Mapping) => {
      return mappingService.deleteSingle(mapping.id).then(() => {
        const mappingId = mapping.id;
        return { mappingId };
      });
    },
    onSuccess: (response) => {
      queryClient.setQueryData(
        ["confirmationMappings", integration.id],
        (oldData: { mappings: Mapping[] }) => {
          return {
            mappings: oldData.mappings.filter(
              (mapping) => mapping.id !== response.mappingId,
            ),
          };
        },
      );
    },
  });

  const { mutateAsync: updateMapping } = useMutation({
    mutationFn: (mapping: Omit<Mapping, "parentMapping" | "children">) => {
      return mappingService
        .updateSingle(mapping.id, mapping)
        .then(handlePromiseError);
    },
    onSuccess: (response) => {
      queryClient.setQueryData(
        ["confirmationMappings", integration.id],
        (oldData: { mappings: Mapping[] }) => {
          return {
            mappings: oldData.mappings.map((mapping) => {
              if (mapping.id === response.mapping.id) {
                return response.mapping;
              }
              return mapping;
            }),
          };
        },
      );
    },
  });

  return {
    mappings,
    confirmationMappings,
    addMapping,
    removeMapping,
    updateMapping,
    connections,
  };
}

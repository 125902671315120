import Box from "/app/src/components/generic/components/box";
import Mappings from "/app/src/components/generic/components/apps/data/mappings";
import NextDivider from "/app/src/components/NextUi/Divider";
import PrimaryMapping from "./primaryMapping";
import { Integration } from "/app/src/models";

/**
 *
 * @param param0 integration
 * @returns DataMappings component
 */
export default function DataMappings({
  integration,
}: {
  integration: Integration;
}) {
  return (
    <Box>
      <PrimaryMapping integration={integration} />
      <NextDivider className="my-3" />
      <Mappings box={false} integration={integration} />
    </Box>
  );
}

import { Input } from "formik-antd";
import { useCallback } from "react";

/**
 * Generic component to display a password field in a formik form. This component
 * controls the placeholder text and onFocus/onBlur of the form. Note: the password
 * is never displayed as the API does not return it.
 */
export default function PasswordField({
  name,
  className = "password",
  passwordExists,
}: {
  name: string;
  className?: string;
  passwordExists: boolean;
}) {
  const onFocus = useCallback((e) => {
    e.target.placeholder = "";
  }, []);

  const onBlur = useCallback(
    (e) => {
      if (passwordExists) {
        e.target.placeholder = "***********";
      }
    },
    [passwordExists],
  );

  return (
    <Input.Password
      className={className}
      onFocus={onFocus}
      onBlur={onBlur}
      placeholder={passwordExists ? "***********" : undefined}
      name={name}
      size="large"
    />
  );
}

import { Mapping } from "/app/src/models";
import EditConfirmationMapping from "./editConfirmationMappings";
import NewConfirmationMapping from "./newConfirmationMapping";
/**
 * Component to edit a group of mappings and add a new mapping to the group of mappings
 * Used for the parentless confirmation mappings and child mappings of a grouping confirmation mapping
 */
export default function ConfirmationMappings({
  confirmationMappings,
  addMapping,
  updateMapping,
  deleteMapping,
  mappings,
}: {
  confirmationMappings: Mapping[];
  addMapping: (mapping: Omit<Mapping, "parentMapping" | "children">) => void;
  updateMapping: (mapping: Omit<Mapping, "parentMapping" | "children">) => void;
  deleteMapping: (mapping: Omit<Mapping, "parentMapping" | "children">) => void;
  mappings: Mapping[];
}) {
  return (
    <>
      {confirmationMappings?.map((mapping) => (
        <div className="mapping" key={mapping.id}>
          <EditConfirmationMapping
            mappings={mappings}
            mapping={mapping}
            updateMapping={updateMapping}
            deleteMapping={deleteMapping}
          />
        </div>
      ))}
      <div>
        <NewConfirmationMapping addMapping={addMapping} mappings={mappings} />
      </div>
    </>
  );
}

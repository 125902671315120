import { useState, useCallback } from "react";
import { Row, Col } from "antd";
import { Formik, FormikProps } from "formik";
import { Form, Input, Select, SubmitButton } from "formik-antd";
import saveIcon from "/app/src/components/generic/title/saveIcon.svg";
import { ReportColumn as ReportColumnT, ReportFilter } from "/app/src/models";
import EditButton from "/app/src/components/generic/components/buttons/EditButton";
import DeleteButton from "/app/src/components/generic/components/buttons/DeleteButton";
import { simpleSchemaBuilder } from "/app/src/helpers";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { reportColumnService } from "/app/src/services";
import { useTranslation } from "react-i18next";

interface FormValues {
  name: string | undefined;
  filteringValue: string | undefined;
}

export const ReportColumn = ({
  column,
  canEdit,

  updateColumn,
}: {
  column: ReportColumnT;
  canEdit: boolean;

  updateColumn: (updatedCol: ReportColumnT) => Promise<any>;
}) => {
  const [editingName, setEditingName] = useState(false);
  const { t } = useTranslation();

  const handleEditClick = useCallback((): void => {
    setEditingName(true);
  }, []);

  const queryClient = useQueryClient();
  const { mutateAsync: deleteColumn } = useMutation({
    mutationFn: (columnId: number) => {
      return reportColumnService.deleteSingle(columnId);
    },
    onSuccess: () => {
      queryClient.setQueryData(
        ["reportColumns", column.reportId],
        (oldData: { report_columns: ReportColumnT[] }) => {
          return {
            report_columns: oldData.report_columns.filter(
              (col) => col.id !== column.id,
            ),
          };
        },
      );
      // Remove any filters that use this column
      queryClient.setQueryData(
        ["reportFilters", column.reportId],
        (oldData: { report_filters: ReportFilter[] }) => {
          return {
            report_filters: oldData.report_filters.filter(
              (filter) => filter.reportColumnId !== column.id,
            ),
          };
        },
      );
    },
  });

  const onSubmitHandler = useCallback(
    async (values: FormValues) => {
      return await updateColumn({ id: column.id, ...values }).then(() => {
        setEditingName(false);
      });
    },
    [column.id, updateColumn],
  );

  const deleteReportColumnHandler = useCallback(() => {
    deleteColumn(column.id);
  }, [column.id, deleteColumn]);

  const columnForm: (props: FormikProps<FormValues>) => JSX.Element =
    useCallback(
      () => (
        <Form>
          <Row>
            {column.custom === "function" ? (
              <>
                <Col span={10}>
                  <Form.Item name="name" hasFeedback={false}>
                    <Input type="text" name="name" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="filteringValue" hasFeedback={false}>
                    <Input
                      type="text"
                      name="filteringValue"
                      placeholder={t("translation:enter_filter_value")}
                    />
                  </Form.Item>
                </Col>
              </>
            ) : (
              <Col span={16}>
                <Form.Item name="name" hasFeedback={false}>
                  <Input type="text" name="name" />
                </Form.Item>
              </Col>
            )}
            {column.type !== "count" ? (
              <Col span={6}>
                <Form.Item name="aggregate" hasFeedback={false}>
                  <Select
                    name="aggregate"
                    placeholder={t("translation:select_aggregate")}
                  >
                    {[
                      { value: "first", label: "First" },
                      { value: "last", label: "Last" },
                      { value: "sum", label: "Sum" },
                      { value: "mean", label: "Mean" },
                      { value: "min", label: "Min" },
                      { value: "max", label: "Max" },
                      { value: "median", label: "Median" },
                    ].map((agg) => (
                      <Select.Option value={agg.value} key={agg.value}>
                        {agg.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            ) : (
              <Col span={6} />
            )}
            <Col span={2}>
              <SubmitButton className="saveButton" type="primary" block>
                <img src={saveIcon} alt="Save" />
              </SubmitButton>
            </Col>
          </Row>
        </Form>
      ),
      [column.custom, column.type, t],
    );
  return (
    <Row gutter={20} className="columnContainer">
      <Col className="name">
        {!editingName ? (
          <div style={{ paddingTop: "8px" }}>{column.title}</div>
        ) : (
          <div style={{ paddingTop: "15px" }} />
        )}
        {editingName ? (
          <Formik
            component={columnForm}
            enableReinitialize
            initialValues={{
              name: column.name,
              filteringValue: column?.filteringValue,
              aggregate: column?.aggregate || "first",
            }}
            validationSchema={simpleSchemaBuilder([
              { name: "name", type: "string", required: true },
            ])}
            onSubmit={onSubmitHandler}
          />
        ) : (
          <div style={{ display: "flex" }}>
            <h3>{column.name}</h3>
            <div style={{ marginLeft: "8px" }}>
              <EditButton onClick={handleEditClick} disabled={!canEdit} />
            </div>
          </div>
        )}
      </Col>
      <Col span={1} offset={10} className="actions">
        <div className="titleActions">
          <DeleteButton
            onClick={deleteReportColumnHandler}
            disabled={!canEdit}
          />
        </div>
      </Col>
    </Row>
  );
};

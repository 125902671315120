import { integrationService, reportService } from "/app/src/services";
import { Integration } from "/app/src/models";
import { buildParams } from "/app/src/helpers/params";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { handlePromiseError } from "/app/src/helpers/api";
import { useCallback } from "react";
import useSetting from "/app/src/components/settings/setting";

interface GeneralSettingsFormValues {
  number?: string | number;
  token?: string;
  reportId?: number;
  creationMode?: string;
}
/**
 * DataHook for the settings of the integration
 */
export default function DataHook(integration: Integration) {
  const queryClient = useQueryClient();
  const { settingValue: creationMode, createUpdateSetting } = useSetting({
    integrationId: integration.id,
    type: "creationMode",
  });
  const { mutateAsync: updateIntegration } = useMutation({
    mutationFn: (values: Integration) => {
      return integrationService
        .updateSingle(integration.id, values)
        .then(handlePromiseError);
    },
    onSuccess: (response) => {
      queryClient.setQueryData(
        ["integration", Number(integration.id)],
        response,
      );
    },
  });
  const handleSubmit = useCallback(
    async (values: GeneralSettingsFormValues) => {
      await updateIntegration({
        number: values.number,
        token: values.token,
        reportId: values.reportId,
      });
      await createUpdateSetting({
        value: values.creationMode,
        type: "creationMode",
        integrationId: integration.id,
      });
    },
    [createUpdateSetting, integration.id, updateIntegration],
  );

  const { data: reports } = useQuery({
    queryKey: ["reports", "LocationContentBreakdown"],
    queryFn: () =>
      reportService.getAll(
        buildParams({ baseTable: "LocationContentBreakdown" }),
      ),
    initialData: { reports: [] },
    select: (data) => data.reports,
  });

  return {
    reports,
    handleSubmit,
    creationMode,
  };
}

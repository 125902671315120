import { Col, Row } from "antd";
import { iField, Integration } from "/app/src/models";
import { useTranslation } from "react-i18next";
import NextDivider from "/app/src/components/NextUi/Divider";
import Box from "/app/src/components/generic/components/box";
import Field from "./field";
import { orderBuilderFields as allFields } from "/app/src/constants/orderBuilderFields";

/**
 * Renders the order fields component.
 *
 * @param integration - The integration object.
 * @param orderFields - The array of order fields.
 * @param createField - The function to create a new field.
 * @param updateField - The function to update an existing field.
 */
export default function OrderFields({
  integration,
  orderFields,
  createField,
  updateField,
}: {
  integration: Integration;
  orderFields: iField[];
  createField: (data: iField) => Promise<void>;
  updateField: (data: iField) => Promise<void>;
}) {
  const { t } = useTranslation();

  return (
    <Box>
      <Row className="pb-3">
        <Col className="text-lg" span={8}>
          {t("translation:order_field")}
        </Col>
      </Row>
      <NextDivider />
      {allFields.map((field) => {
        return (
          <Field
            key={field}
            iField={
              orderFields.find((f) => f.name === field)
                ? orderFields.find((f) => f.name === field)
                : { name: field }
            }
            integration={integration}
            updateField={updateField}
            createField={createField}
            level={"order"}
          />
        );
      })}
    </Box>
  );
}

import { Select, Form } from "formik-antd";
import { useCallback, useState } from "react";
import { ReportColumn } from "/app/src/models";
import { useTranslation } from "react-i18next";
import { Col } from "antd";

/**
 * Component for comparing two columns
 */
export default function Comparison({ columns }: { columns: ReportColumn[] }) {
  const { t } = useTranslation();
  const [columnType, setColumnType] = useState<string | undefined>(undefined);
  /**
   * Function to update the column type state
   */
  const onChange = useCallback(
    (colId) => {
      if (colId === 0) {
        return;
      }
      //find the column  by id
      const column = columns.find((col) => col.id === colId);
      if (column) {
        setColumnType(column.filterType);
      }
    },
    [columns],
  );
  const filteringValues = [
    { value: "percentage", label: t("translation:percentage") },
    { value: "ratio", label: t("translation:ratio") },
    { value: "sum", label: t("translation:sum") },
    { value: "product", label: t("translation:product") },
    { value: "modulus", label: t("translation:modulus") },
    { value: "difference", label: t("translation:difference") },
  ];
  return (
    <>
      <Col span={4}>
        <Form.Item name="firstColumn">
          <Select
            name="firstColumn"
            size="large"
            placeholder={t("translation:select_column")}
            onChange={onChange}
          >
            {columns.map((column) => (
              <Select.Option key={column.id} value={column.id}>
                {column.name}
              </Select.Option>
            ))}
            {columnType === "DateTime" && (
              <Select.Option value={0}>
                {t("translation:current_day")}
              </Select.Option>
            )}
          </Select>
        </Form.Item>
      </Col>
      {columnType !== "DateTime" && (
        <Col span={3}>
          <Form.Item name="filteringValue">
            <Select
              name="filteringValue"
              size="large"
              placeholder={t("translation:select_operation")}
            >
              {filteringValues.map((filter) => (
                <Select.Option key={filter.value} value={filter.value}>
                  {filter.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      )}
      <Col span={4}>
        <Form.Item name="secondColumn">
          <Select
            name="secondColumn"
            size="large"
            placeholder={t("translation:select_column")}
            onChange={onChange}
          >
            {columns.map((column) => (
              <Select.Option key={column.id} value={column.id}>
                {column.name}
              </Select.Option>
            ))}
            {columnType === "DateTime" && (
              <Select.Option value={0}>
                {t("translation:current_day")}
              </Select.Option>
            )}
          </Select>
        </Form.Item>
      </Col>
      {columnType === "DateTime" && (
        <Col span={3}>
          <Form.Item name="filteringValue">
            <Select
              name="filteringValue"
              size="large"
              placeholder={t("translation:unit_of_time")}
            >
              <Select.Option value="minute">
                {t("translation:minute")}
              </Select.Option>
              <Select.Option value="hour">
                {t("translation:hour")}
              </Select.Option>
              <Select.Option value="day">{t("translation:day")}</Select.Option>
              <Select.Option value="week">
                {t("translation:week")}
              </Select.Option>
              <Select.Option value="month">
                {t("translation:month")}
              </Select.Option>
              <Select.Option value="year">
                {t("translation:year")}
              </Select.Option>
            </Select>
          </Form.Item>
        </Col>
      )}
    </>
  );
}

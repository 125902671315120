import { useCallback, useMemo } from "react";
import { Row, Col } from "antd";
import { Form, SubmitButton, Input, Checkbox } from "formik-antd";
import { Formik, FormikProps } from "formik";
import { roleService } from "/app/src/services";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { handleSubmissionErrors } from "/app/src/helpers/forms";
import { simpleSchemaBuilder } from "/app/src/helpers";
import Box from "/app/src/components/generic/components/box";
import { IconToolTip } from "/app/src/components/icons/IconBuilder";
import { useMutation } from "@tanstack/react-query";
import { Role } from "/app/src/models/";
import { handlePromiseError } from "/app/src/helpers/api";

interface FormValues {
  name: string;
  admin: boolean;
}

export default function NewRoleForm() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const labelContent = useMemo(
    () => (
      <>
        {t("translation:full_access")}
        <IconToolTip
          content={t("translation:full_access_description")}
          className="ml-1"
        />
      </>
    ),
    [t],
  );

  const newRoleForm: (props: FormikProps<FormValues>) => JSX.Element =
    useCallback(
      ({ dirty }) => (
        <Form layout="vertical">
          <Row justify="start" gutter={20}>
            <Col span={12}>
              <Form.Item
                name="name"
                label={t("translation:role_name")}
                hasFeedback={false}
              >
                <Input name="name" size="large" />
              </Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item name="admin" label={labelContent}>
                <Checkbox name="admin" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <SubmitButton type="primary" size="large" disabled={!dirty}>
                {t("translation:save")}
              </SubmitButton>
            </Col>
          </Row>
        </Form>
      ),
      [labelContent, t],
    );

  const { mutateAsync: createRole } = useMutation({
    mutationFn: (values: Role) => {
      return roleService.createSingle(values).then(handlePromiseError);
    },
  });

  /**
   * Handles the submission of the form. Creates the role and navigates to the user
   * list page if successful.
   */
  const handleSubmit = useCallback(
    async (values: Role, actions) => {
      return await createRole(values).then((response) => {
        if (response?.errors) {
          handleSubmissionErrors(response.errors, actions.setFieldError);
        } else {
          navigate("/users");
        }
        actions.setSubmitting(false);
      });
    },
    [createRole, navigate],
  );

  return (
    <Box>
      <Formik
        component={newRoleForm}
        initialValues={{ name: "", admin: false }}
        validationSchema={simpleSchemaBuilder([
          { name: "name", type: "string", required: true },
        ])}
        onSubmit={handleSubmit}
      />
    </Box>
  );
}

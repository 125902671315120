import { object, string } from "yup";
import isIpAddress from "/app/src/helpers/validators";
import i18next from "/app/src/locales/config";

export const newConnectionSchema = object().shape({
  name: string().required(i18next.t("translation:name_is_required")),
  type: string().required(i18next.t("translation:type_is_required")),
  username: string().when("type", {
    is: (type) => ["MSSQL", "Basic", "IBM2", "OAuth2"].includes(type),
    then: (schema) => schema.required(i18next.t("translation:required")),
    otherwise: (schema) => schema.nullable(),
  }),
  password: string().when("type", {
    is: (type) => ["MSSQL", "Basic", "IBM2", "OAuth2"].includes(type),
    then: (schema) => schema.required(i18next.t("translation:required")),
    otherwise: (schema) => schema.nullable(),
  }),
  url: string()
    .required(i18next.t("translation:address_is_required"))
    .test(
      "translation:test-name",
      i18next.t("translation:valid_address"),
      (value) => {
        return isIpAddress(value);
      },
    ),
  token: string().when("type", {
    is: (type) => ["MSSQL", "IBM2"].includes(type),
    then: (schema) => schema.required(i18next.t("translation:required")),
    otherwise: (schema) => schema.nullable(),
  }),
  authentication: string().required(i18next.t("translation:required")),
  login: string().when("authentication", {
    is: (type) => ["TBA", "OAuth2"].includes(type),
    then: (schema) =>
      schema
        .required(i18next.t("translation:required"))
        .test(
          "translation:test-name",
          i18next.t("translation:valid_address"),
          (value) => {
            return isIpAddress(value);
          },
        ),
    otherwise: (schema) => schema.nullable(),
  }),
  tokenDuration: string().when("authentication", {
    is: (type) => ["TBA", "OAuth2"].includes(type),
    then: (schema) => schema.required(i18next.t("translation:required")),
    otherwise: (schema) => schema.nullable(),
  }),
  tokenDurationUnit: string().when("authentication", {
    is: (type) => ["TBA", "OAuth2"].includes(type),
    then: (schema) => schema.required(i18next.t("translation:required")),
    otherwise: (schema) => schema.nullable(),
  }),
  refreshEndpoint: string().when("authentication", {
    is: "OAuth2",
    then: (schema) =>
      schema
        .required(i18next.t("translation:required"))
        .test(
          "translation:test-name",
          i18next.t("translation:valid_address"),
          (value) => {
            return isIpAddress(value);
          },
        ),
    otherwise: (schema) => schema.nullable(),
  }),
});

import { Mapping } from "/app/src/models";
import GroupingConfirmationHook from "./groupingConfirmationHook";
import ConfirmationMappings from "./confirmationMappings";
import { IconBuilder } from "/app/src/components/icons/IconBuilder";
import { Col, Row } from "antd";

/**
 * Component to display the child mappings of a grouping confirmation mapping
 * This calls the same component(ConfirmationMappings) that the highest level confirmation mappings call
 * This is done to keep the same styling and functionality and to avoid duplicating code
 */
export default function EditGroupingConfirmationMapping({
  mappings,
  mapping,
}: {
  mappings: Mapping[];
  mapping: Mapping;
}) {
  const { childMappings, addMapping, removeMapping, updateMapping } =
    GroupingConfirmationHook(mapping);

  return (
    <Row gutter={16} justify="start">
      <Col span={1} offset={2}>
        <IconBuilder icon="ArrowBoxRight" size={28} color="#82878e" />
      </Col>
      <Col span={21}>
        <ConfirmationMappings
          confirmationMappings={childMappings}
          mappings={mappings}
          addMapping={addMapping}
          deleteMapping={removeMapping}
          updateMapping={updateMapping}
        />
      </Col>
    </Row>
  );
}

import React, { useState, useEffect, useContext, useCallback } from "react";
import { getDateFormat } from "/app/src/helpers/time";
import { Formik, FormikProps } from "formik";
import { Row, Col } from "antd";
import { Form, InputNumber, Select } from "formik-antd";
import Verb from "./verb";
import Value from "./value";
import TimeZoneContext from "/app/src/contexts/TimeZoneContext";
import { ReportColumn, ReportFilter } from "/app/src/models";
import { useTranslation } from "react-i18next";
import { convertArrayToCSV, convertCSVtoArray } from "/app/src/helpers";
import FormikDisabler from "/app/src/components/generic/components/formikDisabler";
import DisabledSubmitButton from "/app/src/components/generic/components/buttons/DisabledSubmitButton";
import { useAccessContext } from "/app/src/contexts/hooks/useAccessContext";
import { reportFilterService } from "/app/src/services";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateReportFilterSchema } from "/app/src/schemas/reports/reportSchema";
import { handlePromiseError } from "/app/src/helpers/api";
import NextButton from "/app/src/components/NextUi/Button";

function formatForm(formValues: FormValues, timeZone: string) {
  let formattedValue = formValues.value;
  // If value is a list, convert to comma separated string
  formattedValue = convertArrayToCSV(formattedValue);
  if (formValues.dateRange === "specificTime") {
    formattedValue = getDateFormat(
      formValues.value as string,
      "YYYY-MM-DDTHH:mm:ss",
      timeZone,
    );
  }
  return {
    reportColumnId: formValues.reportColumnId,
    value: formattedValue,
    verb: formValues.verb,
    dateRange: formValues.dateRange,
    filterGroup: formValues.filterGroup,
  };
}

interface FormValues {
  reportColumnId: number | undefined;
  verb: string | undefined;
  value: string | string[] | undefined;
  dateRange: string | undefined;
  filterGroup: number | undefined;
}

export default function Filter({
  filter,
  reportColumns,
}: {
  filter: ReportFilter;
  reportColumns: ReportColumn[];
}) {
  const { t } = useTranslation();
  const [filterType, setFilterType] = useState("");
  const [filterVerb, setFilterVerb] = useState("");
  const { timeZone } = useContext(TimeZoneContext);

  const { canEdit } = useAccessContext();
  const queryClient = useQueryClient();

  const { mutateAsync: updateFilter } = useMutation({
    mutationFn: (updatedFilter: ReportFilter) => {
      return reportFilterService
        .updateSingle(filter.id, updatedFilter)
        .then(handlePromiseError);
    },
    onSuccess: (data) => {
      queryClient.setQueryData(
        ["reportFilters", filter.reportId],
        (oldData: { report_filters: ReportFilter[] }) => {
          return {
            report_filters: oldData.report_filters.map(
              (oldFilter: ReportFilter) => {
                return oldFilter.id === filter.id
                  ? data.report_filter
                  : oldFilter;
              },
            ),
          };
        },
      );
    },
  });

  const updateFilterHandler = useCallback(
    async (values: FormValues) => {
      const formattedFormValues = formatForm(values, timeZone);
      await updateFilter(formattedFormValues);
    },
    [timeZone, updateFilter],
  );

  const { mutateAsync: removeFilter } = useMutation({
    mutationFn: (filterId: number) => {
      return reportFilterService.deleteSingle(filterId);
    },
    onSuccess: () => {
      queryClient.setQueryData(
        ["reportFilters", filter.reportId],
        (oldData: { report_filters: ReportFilter[] }) => {
          return {
            report_filters: oldData.report_filters.filter(
              (oldFilter: ReportFilter) => {
                return oldFilter.id !== filter.id;
              },
            ),
          };
        },
      );
      queryClient.invalidateQueries(["report", filter.reportId]);
    },
  });

  const removeFilterHandler = useCallback(() => {
    removeFilter(filter.id);
  }, [filter.id, removeFilter]);

  useEffect(() => {
    if (filter.verb) {
      setFilterVerb(filter.verb);
    }
    setFilterType(filter.reportColumnDataType);
  }, [filter]);

  const filterForm: (props: FormikProps<FormValues>) => JSX.Element =
    useCallback(
      ({ dirty, isValid }) => (
        <Form>
          <FormikDisabler disabled={!canEdit} />
          <Row justify="start" gutter={16}>
            <Col span={2}>
              <Form.Item hasFeedback={false} name="filterGroup" label={""}>
                <InputNumber
                  name="filterGroup"
                  size="large"
                  placeholder={t("translation:select_column")}
                  className="!w-full"
                />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item name="reportColumnId">
                <Select
                  disabled
                  name="reportColumnId"
                  size="large"
                  placeholder={t("translation:select_column")}
                >
                  {reportColumns.map((c) => (
                    <Select.Option key={c.id} value={c.id}>
                      {c.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Verb type={filterType} setFilterVerb={setFilterVerb} />

            <Value
              type={filterType}
              verb={filterVerb}
              filterDateType={filter.dateRange}
            />
            <Col span={2}>
              <DisabledSubmitButton
                disabled={!dirty || !isValid}
                type="primary"
                size="large"
                block
              >
                {t("translation:save")}
              </DisabledSubmitButton>
            </Col>
            <Col span={2}>
              <NextButton
                size="md"
                variant="bordered"
                color="default"
                fullWidth
                onClick={removeFilterHandler}
                className="hover:border-primary-default hover:text-primary-default bg-white"
              >
                {t("translation:remove")}
              </NextButton>
            </Col>
          </Row>
        </Form>
      ),
      [
        canEdit,
        filter.dateRange,
        filterType,
        filterVerb,
        removeFilterHandler,
        reportColumns,
        t,
      ],
    );
  return (
    <div className="filter inList">
      <Formik
        component={filterForm}
        enableReinitialize
        initialValues={{
          reportColumnId: filter.reportColumnId,
          verb: filter.verb,
          dateRange: filter.dateRange,
          value:
            filter.reportColumnDataType !== "DateTime"
              ? convertCSVtoArray(filter.value)
              : filter.value,
          filterGroup: filter.filterGroup,
        }}
        validationSchema={updateReportFilterSchema}
        onSubmit={updateFilterHandler}
      />
    </div>
  );
}

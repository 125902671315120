import React, { useCallback, useState } from "react";
import { Form, SubmitButton, Input, Select } from "formik-antd";
import { Formik, FormikProps } from "formik";
import { Row, Col, Tabs } from "antd";
import { IconBuilder } from "/app/src/components/icons/IconBuilder";
import { useTranslation } from "react-i18next";
import { testConnection } from "/app/src/helpers/connectionsStatus";
import { isAPIType } from "/app/src/helpers/connectionAPI";
import { getConnectionSchema } from "/app/src/helpers/schemas";
import Headers from "./headers";
import TestEndpoint from "./testEndpoint";
import { handleSubmissionErrors } from "/app/src/helpers/forms";
import { Connection as ConnectionType } from "/app/src/models";
import Logs from "./logs";
import { useQueryClient } from "@tanstack/react-query";
import OAuth1 from "./oAuth1";
import TypeField from "./typeField";
import NextButton from "/app/src/components/NextUi/Button";
import TestQuery from "./testQuery";
import NextModal from "/app/src/components/NextUi/Modal";
import ModalBuilder from "/app/src/components/NextUi/helpers/ModalBuilder";
import { useDisclosure } from "@nextui-org/react";
import Box from "/app/src/components/generic/components/box";
import FormikTypeWrapper from "./connectionType/formikTypeWrapper";
import TokenBasedAuthentication from "./tokenBasedAuthentication";

interface FormValues {
  name: string | undefined;
  username: string | undefined;
  url: string | undefined;
  password: string | undefined;
  token: string | undefined;
  authentication: string | undefined;
  type: string | undefined;
}

/*
 * Format form values before submitting
 * do not send empty password and token values
 */
function formatForm(values: FormValues): ConnectionType {
  if (values.password === "") {
    delete values.password;
  }
  if (values.token === "") {
    delete values.token;
  }
  return {
    name: values.name,
    username: values.username,
    url: values.url,
    password: values?.password,
    token: values?.token,
    authentication: values.authentication,
    type: values.type,
  };
}

/**
 * Component to display a connection
 * @param connection the connection to be displayed
 * @param removable whether the connection can be deleted
 * @param updateConnection function to update the connection
 * @param deleteConnection function to delete the connection
 * @param editable whether the connection can be edited
 * @param testable whether the connection can be tested
 */
export default function Connection({
  connection,
  removable,
  updateConnection,
  deleteConnection,
  editable,
  testable,
}: {
  connection: ConnectionType;
  removable?: boolean;
  updateConnection: (
    connection: ConnectionType,
  ) => Promise<
    | { connection: ConnectionType }
    | { errors: { [key: string]: [key: string] } }
  >;
  deleteConnection: (
    connection: ConnectionType,
  ) => Promise<{ deleted: boolean }> | undefined;
  editable?: boolean;
  testable?: boolean;
}) {
  const [isTesting, setIsTesting] = useState(false);
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  /**
   * Handler for testing connection
   */
  const handleTestClick = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      e.stopPropagation();
      testConnection(setIsTesting, connection, updateConnection).finally(() => {
        queryClient.invalidateQueries({
          queryKey: ["logsByConnection", connection.id],
        });
        queryClient.invalidateQueries({
          queryKey: ["logsCountByConnection", connection.id],
        });
      });
    },
    [connection, queryClient, updateConnection],
  );

  /**
   * Handler for deleting connection
   */
  const handleDeleteClick = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      e.stopPropagation();
      deleteConnection(connection);
    },
    [connection, deleteConnection],
  );

  /**
   * Handler for updating connection
   */
  const handleUpdate = useCallback(
    (values: FormValues, actions) => {
      updateConnection({ ...formatForm(values), id: connection.id })
        .catch((errors) => {
          handleSubmissionErrors(errors, actions.setFieldError);
        })
        .finally(() => {
          actions.setSubmitting(false);
        });
    },
    [connection.id, updateConnection],
  );

  const getAuthOptions = useCallback((type: string) => {
    if (type === "REST") {
      return [
        { value: "Basic", label: "Basic" },
        { value: "No Auth", label: "No Auth" },
        { value: "OAuth1", label: "OAuth 1.0" },
        { value: "OAuth2", label: "OAuth 2.0" },
        {
          value: "TBA",
          label: "Token Based Authentication",
        },
      ];
    }
    if (type === "GraphQL") {
      return [
        { value: "No Auth", label: "No Auth" },
        { value: "Basic", label: "Basic" },
        {
          value: "TBA",
          label: "Token Based Authentication",
        },
      ];
    }
    if (type === "MSSQL" || type === "IBM2") {
      return [{ value: "Password", label: "Username/Password" }];
    }
    return [{ value: "No Auth", label: "No Auth" }];
  }, []);

  /**
   * Formik form for connection settings - name, username, password, url
   */
  const settingsForm: (props: FormikProps<FormValues>) => JSX.Element =
    useCallback(
      ({ dirty, isValid, values }) => (
        <Form layout="vertical">
          <h3>{t("translation:connection_settings")}</h3>
          <Row justify="start" gutter={16}>
            <Col span={12}>
              <Form.Item name="name" label={t("translation:name")}>
                <Input suffix name="name" className="user" size="large" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="url" label={t("translation:address")}>
                <Input suffix name="url" className="user" size="large" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <TypeField />
            </Col>
            <Col span={12}>
              <Form.Item
                name="authentication"
                label={t("translation:authentication")}
              >
                <Select name="authentication" size="large">
                  {getAuthOptions(values.type).map((option) => (
                    <Select.Option key={option.value} value={option.value}>
                      {option.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <FormikTypeWrapper />
          </Row>
          <Row justify="start" gutter={16}>
            <Col span={24}>
              <SubmitButton
                type="primary"
                size="large"
                block
                disabled={!dirty || !isValid}
              >
                {t("translation:save")}
              </SubmitButton>
            </Col>
          </Row>
        </Form>
      ),
      [t, getAuthOptions],
    );
  const initialFormValues: FormValues = {
    name: connection?.name,
    username: connection?.username,
    password: "",
    url: connection?.url,
    token: connection?.token,
    type: connection?.type,
    authentication: connection?.authentication,
  };

  const handleOnClick = useCallback(
    (event) => {
      if (!editable) {
        event.stopPropagation();
      }
    },
    [editable],
  );
  return (
    <div className="appSettings">
      <Box>
        <Row justify="start" gutter={16}>
          <Col span={24}>
            <Row
              justify="start"
              gutter={16}
              align="middle"
              onClick={handleOnClick}
            >
              <Col
                span={1}
                style={{
                  display: "inline-flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {testable &&
                  (connection.status && connection.status !== 0 ? (
                    <IconBuilder icon="CircleCheck" size={32} color="#52c41a" />
                  ) : (
                    <IconBuilder icon="Warning" size={32} color="#e00000" />
                  ))}
              </Col>
              <Col span={16}>
                <h2>{connection.name}</h2>
              </Col>
              <Col span={7}>
                <div style={{ float: "right" }}>
                  {testable && (
                    <NextButton
                      size="sm"
                      variant="bordered"
                      color="default"
                      onClick={handleTestClick}
                      className="hover:border-primary-default hover:text-primary-default"
                    >
                      {isTesting
                        ? t("translation:testing___")
                        : t("translation:test_") + t("translation:connection")}
                    </NextButton>
                  )}
                  {removable && (
                    <>
                      <NextButton
                        size="sm"
                        variant="bordered"
                        color="default"
                        onClick={onOpen}
                        className="border-[#a12121] text-[#a12121] bg-white ml-[10px]"
                      >
                        {t("translation:delete")}
                      </NextButton>
                      <NextModal
                        disableAnimation={false}
                        isOpen={isOpen}
                        onOpenChange={onOpenChange}
                        placement="top"
                      >
                        {ModalBuilder({
                          warning: true,
                          modalHeader: t(
                            "translation:confirm_delete_connection",
                          ),
                          modalFooter: (
                            <>
                              <NextButton
                                size="sm"
                                color="default"
                                variant="bordered"
                                onClick={onOpenChange}
                              >
                                {t("translation:cancel")}
                              </NextButton>
                              <NextButton
                                size="sm"
                                color="danger"
                                onClick={handleDeleteClick}
                              >
                                {t("translation:delete")}
                              </NextButton>
                            </>
                          ),
                        })}
                      </NextModal>
                    </>
                  )}
                </div>
              </Col>
            </Row>
            <Tabs
              defaultActiveKey="1"
              tabPosition="left"
              items={[
                {
                  label: <>{t("translation:settings")}</>,
                  key: "1",
                  children: (
                    <>
                      <Formik
                        component={settingsForm}
                        validationSchema={getConnectionSchema(connection)}
                        initialValues={initialFormValues}
                        enableReinitialize
                        onSubmit={handleUpdate}
                      />
                      {isAPIType(connection.type) && (
                        <>
                          <TestEndpoint connection={connection} />
                          {connection.type === "GraphQL" && (
                            <TestQuery connection={connection} />
                          )}
                          <Headers connection={connection} />
                        </>
                      )}
                      {connection.authentication === "OAuth1" && (
                        <OAuth1 connection={connection} />
                      )}
                      {connection.authentication === "OAuth2" && (
                        <TokenBasedAuthentication connection={connection} />
                      )}
                      {connection.authentication === "TBA" && (
                        <TokenBasedAuthentication connection={connection} />
                      )}
                    </>
                  ),
                },
                {
                  label: <>{t("translation:logs")}</>,
                  key: "2",
                  children: <Logs connection={connection} />,
                },
              ]}
            />
          </Col>
        </Row>
      </Box>
    </div>
  );
}

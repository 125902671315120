export const orderBuilderFields = [
  "Name",
  "Deadline",
  "Direction Type",
  "Info1",
  "Info2",
  "Info3",
  "Info4",
  "Info5",
  "Owner",
  "Priority",
  "Recipient",
  "Warehouse Name",
  "Initial Handling Unit",
];

export const orderBuilderLineFields = [
  "Material ID",
  "Material",
  "Material Code",
  "Quantity",
  "Name/Number",
  "Expiry Date",
  "Host Identification",
  "Info1",
  "Info2",
  "Info3",
  "Info4",
  "Info5",
  "Lot",
  "Production Date",
  "Qualification",
  "Release Date",
  "Serial Number",
];

import { Row } from "antd";
import { mappingService, reportColumnTypeService } from "/app/src/services";
import EditMapping from "./editMapping";
import NewMapping from "./newMapping";
import { Integration, Mapping, ReportColumnType } from "/app/src/models";
import { buildParams } from "/app/src/helpers";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { handlePromiseError } from "/app/src/helpers/api";
import { useTranslation } from "react-i18next";
import Box from "/app/src/components/generic/components/box";
import { themes } from "/app/src/constants/themes";

/**
 * Component to display the mappings for a single Integration - Data Pull or Data Push
 */
export default function Mappings({
  integration,
  box = true,
}: {
  integration: Integration;
  box?: boolean;
}) {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { data: columnTypes } = useQuery({
    queryKey: ["columnTypes", integration.baseTable],
    queryFn: () => {
      const params = { baseTable: integration.baseTable };
      if (integration.baseTable === "Material") {
        params["table"] = "Material";
      }
      if (params.baseTable === "Order" && integration.type === "delete") {
        params.baseTable = "Order Deletion";
      }
      return reportColumnTypeService.getAll(buildParams(params));
    },
    enabled: Boolean(
      themes.map((map) => map.value).includes(integration.baseTable) ||
        integration.baseTable === "Order",
    ),
    initialData: { report_column_types: [] },
    select: (data: { report_column_types: ReportColumnType[] }) => {
      return data.report_column_types;
    },
  });

  //Get mappings
  const { data: mappings } = useQuery({
    queryKey: ["mappings", integration.id],
    queryFn: () => {
      return mappingService.getAll(
        buildParams({
          integrationId: integration.id,
          orderby: "key",
          parentMappingId: "none",
        }),
      );
    },
    enabled: Boolean(integration.id),
    initialData: { mappings: [] },
    select: (data: { mappings: Mapping[] }) => {
      return data.mappings;
    },
  });

  const { mutateAsync: addMapping } = useMutation({
    mutationFn: (
      mapping: Omit<Mapping, "parentMapping" | "children"> & {
        dataType?: "DateTime" | "String" | "Number";
      },
    ) => {
      if (integration.appName === "Variance") {
        //after creating a mapping, we need to recreate a report column type
        const dataType = mapping?.dataType;
        if (dataType) {
          delete mapping.dataType;
        }
        return mappingService
          .createSingle(mapping)
          .then(handlePromiseError)
          .then((response) => {
            response.mapping["dataType"] = dataType;
            const reportColumnType: ReportColumnType = {
              name: mapping.key,
              table: "Variance",
              baseTable: integration.baseTable,
              integrationId: integration.id,
              mappingId: response.mapping.id,
              custom: "variance",
              type: dataType,
            };
            return reportColumnTypeService
              .createSingle(reportColumnType)
              .then(handlePromiseError)
              .then(() => response);
          });
      }
      return mappingService.createSingle(mapping).then(handlePromiseError);
    },
    onSuccess: (response) => {
      queryClient.setQueryData(
        ["mappings", integration.id],
        (oldData: { mappings: Mapping[] }) => {
          return {
            mappings: [...oldData.mappings, response.mapping],
          };
        },
      );
    },
  });

  const { mutateAsync: removeMapping } = useMutation({
    mutationFn: (mapping: Mapping) => {
      return mappingService.deleteSingle(mapping.id).then(() => {
        const mappingId = mapping.id;
        return { mappingId };
      });
    },
    onSuccess: (response) => {
      queryClient.setQueryData(
        ["mappings", integration.id],
        (oldData: { mappings: Mapping[] }) => {
          return {
            mappings: oldData.mappings.filter(
              (mapping) => mapping.id !== response.mappingId,
            ),
          };
        },
      );
    },
  });

  const { mutateAsync: updateMapping } = useMutation({
    mutationFn: (mapping: Omit<Mapping, "parentMapping" | "children">) => {
      return mappingService
        .updateSingle(mapping.id, mapping)
        .then(handlePromiseError);
    },
    onSuccess: (response) => {
      queryClient.setQueryData(
        ["mappings", integration.id],
        (oldData: { mappings: Mapping[] }) => {
          return {
            mappings: oldData.mappings.map((mapping) => {
              if (mapping.id === response.mapping.id) {
                return response.mapping;
              }
              return mapping;
            }),
          };
        },
      );
    },
  });

  const MappingWrapper = box ? Box : "div";

  return (
    <MappingWrapper>
      <h1>{t("translation:mappings")}</h1>
      {mappings?.map((mapping) => (
        <div className="mapping" key={mapping.id}>
          <Row justify="start" gutter={16}>
            <EditMapping
              isThemed={integration.baseTable !== null}
              integration={integration}
              mapping={mapping}
              columnTypes={columnTypes}
              updateMapping={updateMapping}
              removeMapping={removeMapping}
            />
          </Row>
        </div>
      ))}
      <div className="newMapping">
        <NewMapping
          integration={integration}
          addMapping={addMapping}
          columnTypes={columnTypes}
        />
      </div>
    </MappingWrapper>
  );
}

import React, { useState, useEffect, useCallback } from "react";
import { Col } from "antd";
import { Form, Input, Select, SelectProps } from "formik-antd";
import { During, BeforeAfter } from "./date";
import { useTranslation } from "react-i18next";
import { FormikValues, useFormikContext } from "formik";

function DateValue({
  verb,
  dateType,
}: {
  verb: string;
  dateType: string | undefined;
}) {
  const [filterDateType, setFilterDateType] = useState("");
  useEffect(() => {
    if (dateType) {
      setFilterDateType(dateType);
    }
  }, [dateType]);
  return (
    <>
      {verb === "during" && <During setFilterDateType={setFilterDateType} />}
      {verb === "before" && (
        <BeforeAfter
          filterDateType={filterDateType}
          setFilterDateType={setFilterDateType}
        />
      )}
      {verb === "after" && (
        <BeforeAfter
          filterDateType={filterDateType}
          setFilterDateType={setFilterDateType}
        />
      )}
    </>
  );
}
function StringValue() {
  //Yup validation triggers before Formik. onChangeHandler is used to trigger validation after Formik.
  const { t } = useTranslation();
  const { validateField, setFieldValue } = useFormikContext();
  const options: SelectProps["options"] = [
    { label: t("translation:no_value"), value: "no_value" },
  ];

  const onChangeHandler = useCallback(
    (formValue: FormikValues) => {
      setFieldValue("value", formValue).then(() => {
        validateField("value");
      });
    },
    [setFieldValue, validateField],
  );

  return (
    <Col span={11}>
      <Form.Item name="value">
        <Select
          name="value"
          placeholder={t("translation:enter_value")}
          size="large"
          mode="tags"
          options={options}
          onChange={onChangeHandler}
        />
      </Form.Item>
    </Col>
  );
}

function DefaultValue() {
  const { t } = useTranslation();

  return (
    <Col span={11}>
      <Form.Item name="value">
        <Input
          name="value"
          placeholder={t("translation:enter_value")}
          size="large"
        />
      </Form.Item>
    </Col>
  );
}
export default function Value({
  type,
  verb,
  filterDateType,
}: {
  type: string;
  verb: string;
  filterDateType?: string | undefined;
}) {
  return (
    <>
      {type === "DateTime" && (
        <DateValue verb={verb} dateType={filterDateType} />
      )}
      {type === "Number" && <StringValue />}
      {type === "String" && <StringValue />}
      {type === "UUID" && <StringValue />}
      {type === "None" && <DefaultValue />}
      {type === null && <DefaultValue />}
    </>
  );
}

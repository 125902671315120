import React from "react";
import { Spinner } from "@nextui-org/react";

interface SpinProps {
  label?: string;
  color?:
    | "default"
    | "primary"
    | "secondary"
    | "success"
    | "warning"
    | "danger";
  size?: "sm" | "md" | "lg";
  labelColor?:
    | "foreground"
    | "primary"
    | "secondary"
    | "success"
    | "warning"
    | "danger";
}

/**
 *
 * @param param0 label, color, size, labelColor
 * @returns NextUI Spinner
 */
const Spin: React.FC<SpinProps> = ({
  size = "lg",
  color,
  labelColor,
  label,
}) => {
  return (
    <Spinner label={label} size={size} color={color} labelColor={labelColor} />
  );
};

export default Spin;
